.site-name {
    color: inherit;
}

.site-name-link {
    color: inherit !important;
    text-decoration-color: transparent !important;
}

.site-name-link:hover {
    text-decoration-color: inherit !important;
}
